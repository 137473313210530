.progress_wrap {
  position: relative;
  &.fixed {
    position: fixed;
    top: 50px;
    left: 0;
    width: calc(100% - 380px);
    z-index: 99;
    padding: 10px;
    background: #fff;
    span.progress_value {
      top: 18px;
    }
  }
  .MuiLinearProgress-root {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 38px;
      background: $darkGray;
      z-index: 9;
    }
    &:after {
      content: '';
      position: absolute;
      left: 10px;
      top: 10px;
      width: 15px;
      height: 18px;
      border-radius: 50%;
      background: radial-gradient(
        36.11% 36.11% at 20% 16.67%,
        #de3957 0%,
        #de3957 100%
      );
      z-index: 99;
    }
    .MuiLinearProgress-bar {
      background: radial-gradient(
        36.11% 36.11% at 20% 16.67%,
        #fcc4cd 0%,
        #de3957 100%
      );
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.19);
      max-width: calc(100% - 60px);
    }
  }
  span.progress_value {
    position: absolute;
    right: 18px;
    top: 8px;
    color: #fff;
  }
}

.edit_contact_box {
  padding: 30px 0;
  border-top: 1px solid $borderColor;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $borderColor;
    transform: translate(-50%, 0);
  }
  ul {
    max-width: 550px;
    li {
      display: flex;
      align-items: center;
      margin: 0 0 15px;
      > label {
        width: 160px;
      }
      > .MuiFormGroup-root,
      .right_content {
        width: calc(100% - 160px);
      }
    }
  }
}

.assign_to_contact_wrap {
  .large_input {
    .MuiAutocomplete-endAdornment {
      display: none;
    }
  }
}

.delete_popup_wrap {
  text-align: center;
  padding: 30px 15px;
  img {
    margin-bottom: 20px;
  }
}

.contact_alert_Wrap {
  background: $primary;
  text-align: center;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  .remove {
    position: absolute;
    top: 10px;
    right: 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
    img {
      filter: brightness(22);
      margin-right: 5px;
    }
  }
}

@media (max-width: 1399px) {
  .progress_wrap.fixed {
    width: 100%;
  }
}
