#toast_custom {
  /* visibility: hidden; */
  /* max-width: 50px; */
  width: max-content;
  height: 50px;
  /*margin-left: -125px;*/
  margin: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;

  position: fixed;
  z-index: 4000;
  left: 0;
  right: 0;
  top: 30px;
  font-size: 17px;
  white-space: nowrap;
  vertical-align: middle;
  visibility: visible;
  -webkit-animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 2s,
    fadeout 0.5s 2.5s;
  animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 4s,
    fadeout 0.5s 4.5s;
}

#toast_custom #img {
  width: 50px;
  height: 50px;

  float: left;

  padding-top: 16px;
  padding-bottom: 16px;

  box-sizing: border-box;

  background-color: 111;
  color: #fff;
}

#toast_custom #desc {
  height: 50px;
  color: #fff;
  /* padding: 16px; */
  padding: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes expand {
  from {
    min-width: 50px;
  }

  to {
    min-width: 350px;
  }
}

@keyframes expand {
  from {
    min-width: 50px;
  }

  to {
    min-width: 350px;
  }
}

@-webkit-keyframes stay {
  from {
    min-width: 350px;
  }

  to {
    min-width: 350px;
  }
}

@keyframes stay {
  from {
    min-width: 350px;
  }

  to {
    min-width: 350px;
  }
}

@-webkit-keyframes shrink {
  from {
    min-width: 350px;
  }

  to {
    min-width: 50px;
  }
}

@keyframes shrink {
  from {
    min-width: 350px;
  }

  to {
    min-width: 50px;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }

  to {
    top: 60px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }

  to {
    top: 60px;
    opacity: 0;
  }
}
