.bg_white_box {
  background: $bgWhite;
  border-radius: 10px;
}
.dashboard_top_wrap .form-group.inline span.label {
  width: 100px;
}
.dashboard_top_wrap .form-group.inline .MuiFormGroup-root,
.dashboard_top_wrap .form-group.inline .MuiAutocomplete-root,
.dashboard_top_wrap .form-group.inline .input_wrap {
  width: calc(100% - 100px);
}
.dashboard_top_wrap_inner {
  max-width: 1170px;
}
.MuiAutocomplete-paper .MuiAutocomplete-listbox li.MuiAutocomplete-option {
  font-size: 12px !important;
  font-family: 'Figtree' !important;
}
.MuiAutocomplete-paper
  .MuiAutocomplete-listbox
  li.MuiAutocomplete-option:hover {
  color: $primary;
}
.select_with_search .MuiAutocomplete-inputRoot .MuiAutocomplete-popupIndicator {
  background: $primary;
  border-radius: 0 5px 5px 0;
  right: -7px;
  position: relative;
  width: 30px;
  height: 30px;
  transform: rotate(0) !important;
  top: -1px;
}
.select_with_search
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-popupIndicator:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 15px;
  height: 8px;
  background: url(../../Img/down-arrow.svg);
  filter: brightness(55);
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
}
.select_with_search
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-popupIndicator
  svg {
  display: none;
}

.dashboard_top_wrap_right .form-group.inline span.label {
  width: 150px;
}

.dashboard_top_wrap_right .form-group.inline .MuiFormGroup-root {
  width: calc(100% - 150px);
}

.dashboard_bottom_wrap {
  border-top: 1px solid $borderColor;
}

.resolution_note_wrap .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.pagination_wrap .MuiPagination-root ul.MuiPagination-ul {
  justify-content: flex-end;
}

.pagination_wrap .MuiPagination-root ul.MuiPagination-ul li button {
  background: #fff;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: $textLight;
}

.pagination_wrap
  .MuiPagination-root
  ul.MuiPagination-ul
  li
  button.Mui-selected {
  color: $primary;
}

.total_entry p {
  color: $textLight;
}

.note_danger {
  background: $bgDangerLight;
  display: inline-block;
  padding: 6px 10px;
  border-radius: 5px;
  color: $primary;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}
.note_danger img {
  margin-left: 20px;
}

.number_comparision {
  max-width: 1100px;
}



/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Overlay background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure modal is above all other content */
}

.modal-content {
  background-color: white;
  width: 80vw;
  height: 80vh;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.close-btn {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-body {
  flex: 1;
  padding: 10px;
  overflow: auto;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
